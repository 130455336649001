<template>
  <base-homepage
    v-if="params"
    :params="params"
    :heading="params.heading"
    color="bg-teal-400"
    :hide-heading="params.hideHeading"
  >
    <template v-if="hasTrustpilot" v-slot:trustpilot
      ><div
        class="has-blue mb-4 text-center sm:mb-4"
        :class="params.hideHeading ? 'sm:mb-6' : 'sm:mb-4'"
      >
        <div
          class="mb-1 text-lg font-medium italic sm:text-xl"
          v-if="params.tpquote"
        >
          "{{ params.tpquote | unencodeURL }}"
        </div>
        <div class="mb-2 text-sm sm:mb-2">
          Rated {{ params.tprating || 4.9 }}/5 on Trustpilot
        </div>
        <div class="flex justify-center space-x-1">
          <div v-for="i in 5" :key="`star-${i}`">
            <font-awesome-icon
              :icon="['fas', 'star']"
              class="text-yellow-400"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="params.subline" v-slot:subline>
      <div class="has-blue mb-6 px-8 text-center text-base sm:mb-16 sm:text-xl">
        {{ params.subline | unencodeURL }}
      </div>
    </template>
  </base-homepage>
</template>

<script>
import BaseHomepage from '../../components/homepage/BaseHomepage.vue';

export default {
  name: 'LandingPage',
  components: {
    BaseHomepage
  },
  data() {
    return {
      params: null,
      hasTrustpilot: false
    };
  },
  mounted() {
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams);
    this.params = {
      tpquote: decodeURIComponent(params.tpquote || ''),
      tprating: params.tprating,
      subline: decodeURIComponent(params.subline || ''),
      trustpilot: params.trustpilot,
      heading: decodeURIComponent(params.heading || ''),
      hideHeading: params.hideHeading
    };
    this.hasTrustpilot = this.params?.trustpilot === 'true';
  }
};
</script>

<style></style>
